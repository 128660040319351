/** @format */

export const PUBLIC_URLS = {
  account: {
    login: "/account/login",
    checkIfLoggedIn: "/account/checkIfLoggedIn",
  },
  school: {
    getSchools: "/school/listPublic",
  },
};
export const PRIVATE_URLS = {
  academicYear: {
    list: "/academicYear/list",
    create: "/academicYear/create",
    update: "/academicYear/update",
    delete: "/academicYear/delete",
    toggleActiveStatus: "/academicYear/toggleActiveStatus",
  },
  account: {
    changePasswordForUser: "/account/changePasswordForUser",
    getCurrentUser: "/account/getCurrentUser",
    changePassword: "/account/changePassword",
  },
  role: {
    list: "/role/list",
    create: "/role/create",
    update: "/role/update",
    delete: "/role/delete",
    getRoleKeys: "/role/getRoleKeys",
  },
  employee: {
    list: "/employee/list",
    create: "/employee/create",
    update: "/employee/update",
    delete: "/employee/delete",
    getEmpById: "/employee/details",
    updateLibraryMember: "/employee/updateLibraryMember",
    removeLibraryMember: "/employee/removeLibraryMember",
    getHodDashboardData: "/employee/getHodDashboardData",
    getDepartmentStudent: "/employee/getDepartmentStudent",
    toggleQuickLink: "employee/toggleQuickLink",
  },
  designation: {
    list: "/designation/list",
    create: "/designation/create",
    update: "/designation/update",
    delete: "/designation/delete",
  },
  department: {
    list: "/department/list",
    create: "/department/create",
    update: "/department/update",
    delete: "/department/delete",
  },

  offerLetter: {
    list: "/offerLetter/list",
    create: "/offerLetter/create",
    update: "/offerLetter/update",
    delete: "/offerLetter/delete",
  },

  experienceLetter: {
    list: "/experienceLetter/list",
    create: "/experienceLetter/create",
    update: "/experienceLetter/update",
    delete: "/experienceLetter/delete",
  },

  relievingLetter: {
    list: "/relievingLetter/list",
    create: "/relievingLetter/create",
    update: "/relievingLetter/update",
    delete: "/relievingLetter/delete",
  },
  school: {
    list: "/school/list",
    create: "/school/create",
    toggleActiveStatus: "/school/toggleActiveStatus",
    update: "/school/update",
    details: "/school/details",
    addFiles: "/school/addFiles",
    removeFile: "/school/removeFile",
  },
  class: {
    list: "/class/list",
    create: "/class/create",
    update: "/class/update",
    delete: "/class/delete",
  },
  section: {
    list: "/section/list",
    create: "/section/create",
    update: "/section/update",
    delete: "/section/delete",
  },
  semester: {
    list: "/semester/list",
    create: "/semester/create",
    update: "/semester/update",
    delete: "/semester/delete",
  },
  subject: {
    list: "/subject/list",
    create: "/subject/create",
    update: "/subject/update",
    delete: "/subject/delete",
    getStudentSubject: "/subject/getStudentSubject",
    getCourseOverView: "/subject/getCourseOverView",
    getAllStudentsForGivenSubject: "/subject/getAllStudentsForGivenSubject",
    getAllSubjectsOfThisSemesterAndDepartment:
      "/subject/getAllSubjectsOfThisSemesterAndDepartment",
    getSubjectsForTimeTable: "/subject/getSubjectsForTimeTable",
    getAllSubjectsOfThisSemesterAndDegreeCode:
      "/subject/getAllSubjectsOfThisSemesterAndDegreeCode",
  },
  fileUpload: {
    list: "/fileUpload/list",
    create: "/fileUpload/create",
    update: "/fileUpload/update",
    delete: "/fileUpload/delete",
  },

  gallery: {
    list: "/gallery/list",
    create: "/gallery/create",
    update: "/gallery/update",
    delete: "/gallery/delete",
    addFiles: "/gallery/addFiles",
    removeFile: "/gallery/removeFile",
    listPublic: "/gallery/listPublic",
  },
  assignment: {
    list: "/assignment/list",
    create: "/assignment/create",
    update: "/assignment/update",
    delete: "/assignment/delete",
  },
  notice: {
    list: "/notice/list",
    create: "/notice/create",
    update: "/notice/update",
    delete: "/notice/delete",
    listPublic: "/notice/listPublic",
  },
  calenderEvent: {
    list: "/calenderEvent/list",
    create: "/calenderEvent/create",
    update: "/calenderEvent/update",
    delete: "/calenderEvent/delete",
    deleteCalenderEvent: "/calenderEvent/deleteCalenderEvent",
  },

  news: {
    list: "/news/list",
    create: "/news/create",
    update: "/news/update",
    delete: "/news/delete",
    listPublic: "/news/listPublic",
  },
  event: {
    list: "/event/list",
    listPublic: "/event/listPublic",
    create: "/event/create",
    update: "/event/update",
    delete: "/event/delete",
  },
  holiday: {
    list: "/holiday/list",
    create: "/holiday/create",
    update: "/holiday/update",
    delete: "/holiday/delete",
  },
  awards: {
    list: "/awards/list",
    create: "/awards/create",
    update: "/awards/update",
    delete: "/awards/delete",
    listPublic: "/awards/listPublic",
  },
  splashNews: {
    list: "/splashNews/list",
    listPublic: "/splashNews/listPublic",
    create: "/splashNews/create",
    update: "/splashNews/update",
    delete: "/splashNews/delete",
    toggle: "/splashNews/toggleEnable",
  },
  student: {
    list: "/student/list",
    create: "/student/create",
    update: "/student/update",
    delete: "/student/delete",
    details: "/student/details",
    overView: "/student/overView",
    promote: "/student/promote",
    resuffle: "/student/resuffle",
    updateTransportMember: "/student/updateTransportMember",
    removeTransportMember: "/student/removeTransportMember",
    updateLibraryMember: "/student/updateLibraryMember",
    removeLibraryMember: "/student/removeLibraryMember",
    updateHostelMember: "/student/updateHostelMember",
    removeHostelMember: "/student/removeHostelMember",
    getDashboardStudentDetails: "/student/getDashboardStudentDetails",
    downloadAllStudentsExcel: "/student/downloadAllStudentsExcel",
    downloadStudentsExcel: "/student/downloadStudentsExcel",
    downloadStudentsPdf: "/student/downloadStudentsPdf",
    updatePhoto: "/student/updatePhoto",
    bulkUpdateSheet: "/student/bulkUpdateSheet",
    bulkUpdate: "/student/bulkUpdate",
    getBulkStudentAdmitSheet: "/student/getBulkStudentAdmitSheet",
    bulkStudentAdmit: "/student/bulkStudentAdmit",
    assignMentor: "/student/assignMentor",
    getCoursePlanStudents: "/student/getCoursePlanStudents",
    generateGuardianCredentials: "/student/generateGuardianCredentials",
    generateSingleGuardianCredential:
      "/student/generateSingleGuardianCredential",
    updateBankInfo: "/student/updateBankInfo",
    getMyCurriculum: "/student/getMyCurriculum",
    getInternalExamSchedules: "/student/getInternalExamSchedules",
    getOnlineExamDetails: "/student/getOnlineExamDetails",
    submitExam: "/student/submitExam",
    getStudentsForSectionAllocation: "/student/getStudentsForSectionAllocation",
    assignSections: "/student/assignSections",

    getMySemesters: "/student/getMySemesters",
    getMySubjectsForSemester: "/student/getMySubjectsForSemester",
    getCourseDetails: "/student/getCourseDetails",
    myCredentials: "/student/myCredentials",
    toggleQuickLink: "/student/toggleQuickLink",
  },
  studentActivity: {
    list: "/studentActivity/list",
    create: "/studentActivity/create",
    update: "/studentActivity/update",
    delete: "/studentActivity/delete",
  },
  course: {
    list: "/course/list",
    create: "/course/create",
    update: "/course/update",
    delete: "/course/delete",
    uploadCourseMaterial: "/course/uploadCourseMaterial",
    deleteMaterial: "/course/deleteCourseMaterial",
  },
  meeting: {
    list: "/meeting/list",
    create: "/meeting/create",
    update: "/meeting/update",
    delete: "/meeting/delete",
    listStudent: "/meeting/listStudent",
    join: "/meeting/join",
  },
  visitorInfo: {
    list: "/visitorInfo/list",
    create: "/visitorInfo/create",
    update: "/visitorInfo/update",
    delete: "/visitorInfo/delete",
    updateCheckout: "/visitorInfo/updateCheckout",
  },

  studentCheckout: {
    list: "/studentCheckout/list",
    update: "/studentCheckout/update",
    delete: "/studentCheckout/delete",
    downloadPdf: "/studentCheckout/downloadPdf",
    donwloadExcel: "/studentCheckout/downloadExcel",
  },
  guardianFeedback: {
    list: "/guardianFeedback/list",
    listPublic: "/guardianFeedback/listPublic",
    create: "/guardianFeedback/create",
    update: "/guardianFeedback/update",
    delete: "/guardianFeedback/delete",
  },
  examGrade: {
    list: "/examGrade/list",
    create: "/examGrade/create",
    update: "/examGrade/update",
    delete: "/examGrade/delete",
  },
  examTerm: {
    list: "/examTerm/list",
    create: "/examTerm/create",
    update: "/examTerm/update",
    delete: "/examTerm/delete",
  },
  examSchedule: {
    list: "/examSchedule/list",
    create: "/examSchedule/create",
    update: "/examSchedule/update",
    delete: "/examSchedule/delete",
    generateHallTicket: "/examSchedule/generateHallTicket",
  },
  examAttendance: {
    details: "/examAttendance/details",
    update: "/examAttendance/update",
  },
  receiptTitle: {
    list: "/receiptTitle/list",
    create: "/receiptTitle/create",
    update: "/receiptTitle/update",
    toggleActiveStatus: "/receiptTitle/toggleActiveStatus",
  },
  courseContent: {
    list: "/courseContent/list",
    create: "/courseContent/create",
    updateChapterDetails: "/courseContent/updateChapterDetails",
    deleteChapter: "/courseContent/deleteChapter",
    // create content
    addContentToChapter: "/courseContent/addContentToChapter",
    updateContent: "/courseContent/updateContent",
    getDetailsTeachers: "courseContent/getDetailsTeachers",
    deleteContent: "/courseContent/deleteContent",
    changeOrderSequence: "/courseContent/changeOrderSequence",
  },

  roomType: {
    list: "/roomType/list",
    create: "/roomType/create",
    update: "/roomType/update",
    delete: "/roomType/delete",
  },
  room: {
    list: "/room/list",
    create: "/room/create",
    update: "/room/update",
    delete: "/room/delete",
    updateBed: "/room/updateBed",
    toggleBedStatus: "/room/toggleBedStatus",
  },
  hostel: {
    list: "/hostel/list",
    create: "/hostel/create",
    update: "/hostel/update",
    delete: "/hostel/delete",
  },
  firm: {
    list: "/firm/list",
    create: "/firm/create",
    update: "/firm/update",
    delete: "/firm/delete",
  },
  vehicle: {
    list: "/vehicle/list",
    create: "/vehicle/create",
    update: "/vehicle/update",
    delete: "/vehicle/delete",
  },
  route: {
    list: "/route/list",
    create: "/route/create",
    update: "/route/update",
    delete: "/route/delete",
    addStop: "/route/addStop",
    removeStop: "/route/removeStop",
    updateStop: "/route/updateStop",
  },

  vehicleLog: {
    list: "/vehicleLog/list",
    create: "/vehicleLog/create",
    update: "/vehicleLog/update",
    delete: "/vehicleLog/delete",
    downloadPdf: "/vehicleLog/downloadPdf",
  },

  maintenanceFuel: {
    list: "/maintenance/listFuel",
    create: "/maintenance/createFuel",
    update: "/maintenance/updateFuel",
    delete: "/maintenance/deleteFuel",
  },

  maintenanceRepair: {
    list: "/maintenance/listRepair",
    create: "/maintenance/createRepair",
    update: "/maintenance/updateRepair",
    delete: "/maintenance/deleteRepair",
  },

  maintenanceTyre: {
    list: "/maintenance/listTyre",
    create: "/maintenance/createTyre",
    update: "/maintenance/updateTyre",
    delete: "/maintenance/deleteTyre",
  },

  maintenanceGreecing: {
    list: "/maintenance/listGreecing",
    create: "/maintenance/createGreecing",
    update: "/maintenance/updateGreecing",
    delete: "/maintenance/deleteGreecing",
  },

  feeMapCategory: {
    list: "/feeMapCategory/list",
    create: "/feeMapCategory/create",
    createMultiple: "/feeMapCategory/createMultiple",
    update: "/feeMapCategory/update",
    delete: "/feeMapCategory/delete",
  },
  feeMap: {
    list: "/feeMap/list",
    create: "/feeMap/create",
    update: "/feeMap/update",
    delete: "/feeMap/delete",
    toggleActiveStatus: "/feeMap/toggleActiveStatus",
  },
  receipt: {
    getStudentsList: "/receipt/getStudentsList",
    getFeeDetails: "/receipt/getFeeDetails",
    collectFees: "/receipt/collectFees",
    previewReceipt: "/receipt/previewReceipt",
    getReceiptsPaidWithCheque: "/receipt/getReceiptsPaidWithCheque",
    updateSingleReceiptReconciliationStatusStatus:
      "/receipt/updateSingleReceiptReconciliationStatus",
    updateMultipleReceiptReconciliationStatus:
      "/receipt/updateMultipleReceiptReconciliationStatus",
    getAmountCollectedWithDifferentModes:
      "/receipt/getAmountCollectedWithDifferentModes",
    getBalanceFeeReport: "/receipt/getBalanceFeeReport",
    downloadBalanceFeeReport: "/receipt/downloadBalanceFeeReport",
    getAllReceipts: "/receipt/getAllReceipts",
    downloadReceipt: "/receipt/downloadReceipt",
    downloadReceiptStaff: "/receipt/downloadReceiptStaff",
    downloadFeeOverView: "/receipt/downloadFeeOverView",
    getAllPastDues: "/receipt/getAllPastDues",
    getMyReceipts: "/receipt/getMyReceipts",
  },
  salaryGrade: {
    list: "/salaryGrade/list",
    create: "/salaryGrade/create",
    update: "/salaryGrade/update",
    delete: "/salaryGrade/delete",
  },
  paymentHistory: {
    downloadPdf: "/paymentHistory/downloadPdf",
    list: "/paymentHistory/list",
    downloadDeductionPdf: "/paymentHistory/downloadDeductionPdf",
  },

  book: {
    list: "/book/list",
    create: "/book/create",
    update: "/book/update",
    delete: "/book/delete",
  },

  bookIssue: {
    list: "/bookIssue/list",
    create: "/bookIssue/create",
    submit: "/bookIssue/submit",
    delete: "/bookIssue/delete",
    downloadPdf: "/bookIssue/downloadPdf",
    downloadExcel: "/bookIssue/downloadExcel",
  },
  periodical: {
    list: "/periodical/list",
    create: "/periodical/create",
    update: "/periodical/update",
    delete: "/periodical/delete",
  },
  leaveType: {
    list: "/leaveType/list",
    create: "/leaveType/create",
    update: "/leaveType/update",
    delete: "/leaveType/delete",
  },
  leaveApplication: {
    list: "/leaveApplication/list",
    create: "/leaveApplication/createApplicationForEmployee",
    listEmployeeApplications: "/leaveApplication/listEmployeeApplications",
    employeeLeaveCredits: "/leaveApplication/employeeLeaveCredits",
    approveLeave: "/leaveApplication/approveLeave",
    rejectLeave: "/leaveApplication/rejectLeave",
    downloadExcel: "/leaveApplication/downloadExcel",
    downloadPdf: "/leaveApplication/downloadPdf",

    createApplicationForStudent:
      "/leaveApplication/createApplicationForStudent",
    listMyApplications: "/leaveApplication/listMyApplications",
    delete: "/leaveApplication/delete",
    getLeavesAppliedByUserReport:
      "/leaveApplication/getLeavesAppliedByUserReport",

    listApplicationsForApproval:
      "/leaveApplication/listApplicationsForApproval",

    getMyWardApplications: "/leaveApplication/getMyWardApplications",

    approveMyWardLeave: "/leaveApplication/approveMyWardLeave",
  },
  studentMarks: {
    listStudentMarks: "/studentMark/listStudentMarks",
    updateStudentsMarks: "/studentMark/updateStudentsMarks",
    getbulkUpdateStudentMarks: "/studentMark/getbulkUpdateStudentMarks",
    bulkUpdateStudentMarks: "/studentMark/bulkUpdateStudentMarks",
    getExamResult: "/studentMark/getExamResult",
    getbulkUpdateAllSectionStudentMarks:
      "/studentMark/getbulkUpdateAllSectionStudentMarks",
    downloadExamResult: "/studentMark/downloadExamResult",
    getGraphDataSubjectWise: "/studentMark/getGraphDataSubjectWise",
    getGraphDataDivisionWise: "/studentMark/getGraphDataDivisionWise",
    donwloadMarksCard: "/studentMark/donwloadMarksCard",
  },
  teacherActivity: {
    list: "/teacherActivity/list",
    create: "/teacherActivity/create",
    update: "/teacherActivity/update",
    delete: "/teacherActivity/delete",
  },
  storage: {
    details: "/storage/getDetails",
  },
  preadmissionEnqiry: {
    list: "/preadmissionEnqiry/list",
    create: "/preadmissionEnqiry/create",
    update: "/preadmissionEnqiry/updateStatus",
    // update: "/preadmissionEnqiry/update",
    delete: "/preadmissionEnqiry/delete",
  },
  preadmissionExam: {
    list: "/preadmissionExam/list",
    create: "/preadmissionExam/create",
    update: "/preadmissionExam/update",
    delete: "/preadmissionExam/delete",
  },
  preadmissionExamSchedule: {
    list: "/preadmissionExamSchedule/list",
    create: "/preadmissionExamSchedule/create",
    update: "/preadmissionExamSchedule/update",
    delete: "/preadmissionExamSchedule/delete",
    enableExamLink: "/preadmissionExamSchedule/enableExamLink",
    getVenueDetails: "/preadmissionExamSchedule/getVenueDetails",
    getExamDetails: "/preadmissionExamSchedule/getExamDetails",
    sendVenueDetailsToStudents:
      "/preadmissionExamSchedule/sendVenueDetailsToStudents",
  },
  employeeAttendance: {
    list: "/employeeAttendance/list",
    update: "/employeeAttendance/update",
    getAttendanceReport: "/employeeAttendance/getAttendanceReport",
    getEmployeeAttendanceSummaryForToday:
      "/employeeAttendance/getEmployeeAttendanceSummaryForToday",
  },
  studentAttendance: {
    create: "/studentAttendance/create",
    getTodaysCourses: "/studentAttendance/getTodaysCourses",
    list: "/studentAttendance/list",
    update: "/studentAttendance/update",
    getStudentAttendanceForSingleSubject:
      "/studentAttendance/getStudentAttendanceForSingleSubject",
    getAttendanceReport: "/studentAttendance/getAttendanceReport",
    getAttendanceOverview: "/studentAttendance/getAttendanceOverview",
    donwloadAbsentReport: "/studentAttendance/donwloadAbsentReport",
    getAttendanceSummaryForToday:
      "/studentAttendance/getAttendanceSummaryForToday",
    getMyAttendance: "/studentAttendance/getMyAttendance",
    getAttendanceReportInBreaks:
      "/studentAttendance/getAttendanceReportInBreaks",
    getStudentWithBelowAttendance:
      "/studentAttendance/getStudentWithBelowAttendance",

    updateAttendanceForExam: "/studentAttendance/updateAttendanceForExam",

    getAttendanceDetails: "/studentAttendance/getAttendanceDetails",
  },
  report: {
    groupedLibraryData: "/report/groupedLibraryData",
    downloadGroupedLibraryData: "/report/downloadGroupedLibraryData",
    getStudentActivityReport: "/report/getStudentActivityReport",
    getStudentActivityReportPdf: "/report/getStudentActivityReportPdf",
    getStudentReport: "/report/getStudentReport",
    getAllEmployeesAttendanceReportForParticularMonth:
      "/report/getAllEmployeesAttendanceReportForParticularMonth",
    getEmployeeAttendanceReport: "/report/getEmployeeAttendanceReport",
    getAllStudentsAttendanceReportForParticularMonth:
      "/report/getAllStudentsAttendanceReportForParticularMonth",

    getStudentAttendanceReport: "/report/getStudentAttendanceReport",

    downloadStudentReport: "/report/downloadStudentReport",
  },
  certificate: {
    getStudyCertificate: "/certificate/getStudyCertificate",
    getBulkStudyCertificate: "/certificate/getBulkStudyCertificate",
    getTransferCertificate: "/certificate/getTransferCertificate",
    getBulkTransferCertificate: "/certificate/getBulkTransferCertificate",
  },
  item: {
    list: "/item/list",
    create: "/item/create",
    update: "/item/update",
    delete: "/item/delete",
    downloadPdf: "/item/downloadPdf",
    downloadExcel: "/item/downloadExcel",
  },
  vendor: {
    list: "/vendor/list",
    create: "/vendor/create",
    update: "/vendor/update",
  },
  issue: {
    list: "/issue/list",
    create: "/issue/create",
    updateStatus: "/issue/updateStatus",
  },
  building: {
    list: "/building/list",
    create: "/building/create",
    update: "/building/update",
    delete: "/building/delete",
    downloadSampleSheet: "/building/downloadSampleSheet",
    addBuildingBulk: "/building/addBuildingBulk",
  },

  degree: {
    list: "/degree/list",
    create: "/degree/create",
    update: "/degree/update",
    delete: "/degree/delete",
  },
  degreeCode: {
    list: "/degreeCode/list",
    create: "/degreeCode/create",
    update: "/degreeCode/update",
    delete: "/degreeCode/delete",
  },

  buildingRoom: {
    list: "/buildingRoom/list",
    create: "/buildingRoom/create",
    update: "/buildingRoom/update",
    delete: "/buildingRoom/delete",
  },
  subjectType: {
    list: "/subjectType/list",
    create: "/subjectType/create",
    update: "/subjectType/update",
    delete: "/subjectType/delete",
  },

  subjectCategory: {
    list: "/subjectCategory/list",
    create: "/subjectCategory/create",
    update: "/subjectCategory/update",
    delete: "/subjectCategory/delete",
  },
  subjectComponent: {
    list: "/subjectComponent/list",
    create: "/subjectComponent/create",
    update: "/subjectComponent/update",
    delete: "/subjectComponent/delete",
  },
  courseOutcome: {
    list: "/courseOutcome/list",
    create: "/courseOutcome/create",
    update: "/courseOutcome/update",
    delete: "/courseOutcome/delete",
  },
  pso: {
    list: "/pso/list",
    create: "/pso/create",
    update: "/pso/update",
    delete: "/pso/delete",
  },
  programOutcome: {
    list: "/programOutcome/list",
    create: "/programOutcome/create",
    update: "/programOutcome/update",
    delete: "/programOutcome/delete",
  },
  coPoMapping: {
    list: "/coPoMapping/list",
    create: "/coPoMapping/create",
    update: "/coPoMapping/update",
    delete: "/coPoMapping/delete",
  },

  coPsoMapping: {
    list: "/coPsoMapping/list",
    create: "/coPsoMapping/create",
    update: "/coPsoMapping/update",
    delete: "/coPsoMapping/delete",
  },
  task: {
    list: "/task/list",
    create: "/task/create",
    update: "/task/update",
    delete: "/task/delete",
  },
  asset: {
    list: "/asset/list",
    create: "/asset/create",
    update: "/asset/update",
    delete: "/asset/delete",
  },
  slot: {
    list: "/slot/list",
    create: "/slot/create",
    update: "/slot/update",
    delete: "/slot/delete",
  },
  studentTimeTable: {
    list: "/studentTimeTable/list",
    create: "/studentTimeTable/create",
    update: "/studentTimeTable/update",
    delete: "/studentTimeTable/delete",
    getStudentTimeTable: "/studentTimeTable/getStudentTimeTable",
    getEmployeeTimeTable: "/studentTimeTable/getEmployeeTimeTable",
    getTimetableMetaData: "/studentTimeTable/getTimetableMetaData",
  },
  // HOD
  employeeSubjectMap: {
    list: "/employeeSubjectMap/list",
    assignSubjects: "/employeeSubjectMap/assignSubjects",
    removeSubject: "/employeeSubjectMap/removeSubject",
    getMyFilters: "/employeeSubjectMap/myFilters",
    upcomingLectures: "/employeeSubjectMap/upcomingLectures",
    getMyCourses: "/employeeSubjectMap/getMyCourses",
    getStudents: "/employeeSubjectMap/getStudents",
    getTimetableSubjectDetails:
      "/employeeSubjectMap/getTimetableSubjectDetails",
    getMySubjects: "/employeeSubjectMap/getMySubjects",
    getStudentsForExam: "/employeeSubjectMap/getStudentsForExam",
  },

  examTitle: {
    list: "/examTitle/list",
    create: "/examTitle/create",
    update: "/examTitle/update",
    delete: "/examTitle/delete",
    getMyExamTitles: "/examTitle/getMyExamTitles",
    getTitlesForCoeAndEc: "/examTitle/getTitlesForCoeAndEc",
  },

  seatingArrangement: {
    list: "/seatingArrangement/list",
    create: "/seatingArrangement/create",
    update: "/seatingArrangement/update",
    delete: "/seatingArrangement/delete",
  },

  labBatch: {
    list: "/labBatch/list",
    create: "/labBatch/create",
    update: "/labBatch/update",
    delete: "/labBatch/delete",
    addStudents: "/labBatch/addStudents",
    removeStudents: "/labBatch/removeStudents",
  },

  savedQuestion: {
    list: "/savedQuestion/list",
    create: "/savedQuestion/create",
    update: "/savedQuestion/update",
    delete: "/savedQuestion/delete",
    toggleApprove: "/savedQuestion/toggleApprove",
  },

  cieExam: {
    list: "/cieExam/list",
    create: "/cieExam/create",
    update: "/cieExam/update",
    delete: "/cieExam/delete",
    getMarksUpdateSheet: "/cieExam/getMarksUpdateSheet",
    uploadMarks: "/cieExam/uploadMarks",
    getSingleMarksUpdateSheet: "/cieExam/getSingleMarksUpdateSheet",
    uploadMarksSingle: "/cieExam/uploadMarksSingle",
    downloadStudentMarks: "/cieExam/downloadStudentMarks",
    getCOAttainment: "/cieExam/getCOAttainment",
    getPOAttainment: "/cieExam/getPOAttainment",
    getPSOAttainment: "/cieExam/getPSOAttainment",
    getCOAttainmentCourseLevel: "/cieExam/getCOAttainmentCourseLevel",
  },

  seat: {
    list: "/seat/list",
    create: "/seat/create",
    delete: "/seat/delete",
    deleteAll: "/seat/deleteAll",
  },

  offeredSubject: {
    list: "/offeredSubject/list",
    create: "/offeredSubject/create",
    publishOfferedSubject: "/offeredSubject/publishOfferedSubject",
    delete: "/offeredSubject/delete",
    getOfferedSubjects: "/offeredSubject/getOfferedSubjects",
    registerSubject: "/offeredSubject/registerSubject",
  },

  semesterSubjectMapping: {
    list: "/semesterSubjectMapping/list",
    create: "/semesterSubjectMapping/create",
    update: "/semesterSubjectMapping/update",
    delete: "/semesterSubjectMapping/delete",
    processSubjectAllocation:
      "/semesterSubjectMapping/processSubjectAllocation",
  },

  questionnaire: {
    list: "/questionnaire/list",
    update: "/questionnaire/update",
    create: "/questionnaire/create",
    delete: "/questionnaire/delete",
    deleteAll: "/questionnaire/deleteAll",
    getMyQuestionnaires: "/questionnaire/getMyQuestionnaires",
    toggleActiveStatus: "/questionnaire/toggleActiveStatus",
  },

  assessmentPlan: {
    list: "/assessmentPlan/list",
    create: "/assessmentPlan/create",
    update: "/assessmentPlan/update",
    delete: "/assessmentPlan/delete",
    details: "/assessmentPlan/details",
    getExamTitles: "/assessmentPlan/getExamTitles",
  },
  achievement: {
    list: "/achievement/list",
    create: "/achievement/create",
    update: "/achievement/update",
    delete: "/achievement/delete",
    toggleApproveStatus: "/achievement/toggleApproveStatus",
  },
  collegeEvent: {
    list: "/collegeEvent/list",
    create: "/collegeEvent/create",
    update: "/collegeEvent/update",
    delete: "/collegeEvent/delete",
    toggleApproveStatus: "/collegeEvent/toggleApproveStatus",
  },
  resourceRequest: {
    list: "/resourceRequest/list",
    create: "/resourceRequest/create",
    update: "/resourceRequest/update",
    delete: "/resourceRequest/delete",
  },
  publication: {
    list: "/publication/list",
    create: "/publication/create",
    update: "/publication/update",
    delete: "/publication/delete",
    toggleApproveStatus: "/publication/toggleApproveStatus",
  },
  coursePlan: {
    list: "/coursePlan/list",
    delete: "/coursePlan/delete",
    myCoursePlan: "/coursePlan/myCoursePlan",
    updateStatus: "/coursePlan/updateStatus",
    updatePlan: "/coursePlan/updatePlan",
    updateFaculty: "/coursePlan/updateFaculty",
    getMyCoursePlanSubjects: "/coursePlan/getMyCoursePlanSubjects",
    getWeeklyCoursePlans: "/coursePlan/getWeeklyCoursePlans",
    getDaysOfCoursePlan: "/coursePlan/getDaysOfCoursePlan",
    addCourseMaterial: "/coursePlan/addCourseMaterial",
    updateCourseMaterial: "/coursePlan/updateCourseMaterial",
    removeCourseMaterial: "/coursePlan/removeCourseMaterial",
    updateSchedule: "/coursePlan/updateSchedule",
    getEmployeeAttendanceStats: "/coursePlan/getEmployeeAttendanceStats",
  },
  academicCalender: {
    updateTerms: "/academicCalender/updateTerms",
    updateAdmissionDates: "/academicCalender/updateAdmissionDates",
    updateFeePaymentDeadlines: "/academicCalender/updateFeePaymentDeadlines",
    updateEvents: "/academicCalender/updateEvents",
    updateCoCurricularActivities:
      "/academicCalender/updateCoCurricularActivities",
    updatePlacementActivities: "/academicCalender/updatePlacementActivities",
    updateProjectSubmissionDeadlines:
      "/academicCalender/updateProjectSubmissionDeadlines",
    updateMeetings: "/academicCalender/updateMeetings",
    updateSpecialPrograms: "/academicCalender/updateSpecialPrograms",
    getDetails: "/academicCalender/getDetails",

    updateSingleEvent: "/academicCalender/updateSingleEvent",
    updateSingleTerm: "/academicCalender/updateSingleTerm",
    updateSingleFeePaymentDeadline:
      "/academicCalender/updateSingleFeePaymentDeadline",
    updateSingleCoCurricularActivity:
      "/academicCalender/updateSingleCoCurricularActivity",
    updateSinglePlacementActivity:
      "/academicCalender/updateSinglePlacementActivity",
    updateSingleProjectSubmissionDeadline:
      "/academicCalender/updateSingleProjectSubmissionDeadline",
    updateSingleMeeting: "/academicCalender/updateSingleMeeting",

    updateSingleSpecialProgram: "/academicCalender/updateSingleSpecialProgram",
  },
  courseAssignment: {
    list: "/courseAssignment/list",
    create: "/courseAssignment/create",
    update: "/courseAssignment/update",
    toggleEnableSubmission: "/courseAssignment/toggleEnableSubmission",
    getMyAssignments: "/courseAssignment/getMyAssignments",
    submitAssigment: "/courseAssignment/submitAssigment",
    delete: "/courseAssignment/delete",
  },

  feedback: {
    list: "/feedback/list",
    create: "/feedback/create",
  },
  copoAssessmentDistribution: {
    update: "/copoAssessmentDistribution/update",
    getDetails: "/copoAssessmentDistribution/getDetails",
  },

  studentReport: {
    list: "/studentReport/list",
    create: "/studentReport/create",
    removeReport: "/studentReport/removeReport",
  },
  mentorMenteeReport: {
    list: "/mentorMenteeReport/list",
    create: "/mentorMenteeReport/create",
    delete: "/mentorMenteeReport/delete",
    getDetails: "/mentorMenteeReport/getDetails",
    getReport: "/mentorMenteeReport/getReport",
  },
  facultyReport: {
    list: "/facultyReport/list",
    create: "/facultyReport/create",
    removeReport: "/facultyReport/removeReport",
  },

  leaveAuth: {
    update: "/leaveAuth/update",
    details: "/leaveAuth/details",
  },
  messItem: {
    create: "/messItem/create",
    update: "/messItem/update",
    list: "/messItem/list",
    delete: "/messItem/delete",
  },
  messMenu: {
    create: "/messMenu/create",
    update: "/messMenu/update",
    list: "/messMenu/list",
    delete: "/messMenu/delete",
  },
  mess: {
    create: "/mess/create",
    update: "/mess/update",
    list: "/mess/list",
    delete: "/mess/delete",
  },
  announcement: {
    create: "/announcement/create",
    update: "/announcement/update",
    list: "/announcement/list",
    delete: "/announcement/delete",
  },
  guardian: {
    list: "/guardian/list",
    toggleActiveStatus: "/guardian/toggleActiveStatus",
    getSemeters: "/guardian/getSemesters",
    getAllSubjects: "/guardian/getAllSubjects",
    getAttendance: "/guardian/getAttendance",
    getProctorDetails: "/guardian/getProctorDetails",
    getTimeTable: "/guardian/getTimeTable",
    getDashboardData: "/guardian/getDashboardData",
    updatePassword: "/guardian/updatePassword",
  },
  academicRegulation: {
    getDetails: "/academicRegulation/getDetails",
    update: "/academicRegulation/update",
    delete: "/academicRegulation/delete",
  },
  proctorMessage: {
    create: "/proctorMessage/create",
    update: "/proctorMessage/update",
    list: "/proctorMessage/list",
    getMyMessages: "/proctorMessage/getMyMessages",
    delete: "/proctorMessage/delete",
  },
  reExamApplication: {
    create: "/reExamApplication/create",
    update: "/reExamApplication/update",
    list: "/reExamApplication/list",
    delete: "/reExamApplication/delete",
  },

  scholarship: {
    create: "/scholarship/create",
    update: "/scholarship/update",
    list: "/scholarship/list",
    delete: "/scholarship/delete",
  },

  codeOfConduct: {
    create: "/codeOfConduct/create",
    update: "/codeOfConduct/update",
    list: "/codeOfConduct/list",
    delete: "/codeOfConduct/delete",
  },

  internship: {
    create: "/internship/create",
    update: "/internship/update",
    list: "/internship/list",
    approve: "/internship/approve",
    delete: "/internship/delete",
    addDailyReport: "/internship/addDailyReport",
  },
  invigilatorRole: {
    create: "/invigilatorRole/create",
    update: "/invigilatorRole/update",
    list: "/invigilatorRole/list",
    delete: "/invigilatorRole/delete",
  },
  curriculum: {
    update: "/curriculum/update",
    list: "/curriculum/list",
    delete: "/curriculum/delete",
    deleteDetail: "/curriculum/deleteDetail",
    updateDetail: "/curriculum/updateDetail",
  },
  internalExam: {
    create: "/internalExam/create",
    update: "/internalExam/update",
    list: "/internalExam/list",
    delete: "/internalExam/delete",
    getSingleMarksUpdateSheet: "/internalExam/getSingleMarksUpdateSheet",
    uploadMarksSingle: "/internalExam/uploadMarksSingle",
    downloadStudentMarks: "/internalExam/downloadStudentMarks",
    getCOAttainment: "/internalExam/getCOAttainment",
    getPOAttainment: "/internalExam/getPOAttainment",
    getPSOAttainment: "/internalExam/getPSOAttainment",
    getCOAttainmentCourseLevel: "/internalExam/getCOAttainmentCourseLevel",
    getExamsToUploadMarks: "/internalExam/getExamsToUploadMarks",
    getExamsToViewMarks: "/internalExam/getExamsToViewMarks",
    createExternal: "/internalExam/createExternal",
    getStudentsForResultProcessing:
      "/internalExam/getStudentsForResultProcessing",
    processResult: "/internalExam/processResult",
    getCoeDashboardData: "/internalExam/getCoeDashboardData",
    getExamCoordinatorDashboardData:
      "/internalExam/getExamCoordinatorDashboardData",
    getGpa: "/internalExam/getGpa",
    getMarksToUpdate: "/internalExam/getMarksToUpdate",
    updateMarks: "/internalExam/updateMarks",
    getCgpa: "internalExam/getCgpa",
    getEligibilityReport: "/internalExam/getEligibilityReport",

    downloadEligibilityReport: "/internalExam/downloadEligibilityReport",
  },
  myQuestionBank: {
    create: "/myQuestionBank/create",
    createAuto: "/myQuestionBank/createAuto",
    update: "/myQuestionBank/update",
    list: "/myQuestionBank/list",
    delete: "/myQuestionBank/delete",
  },
  internalExamSchedule: {
    create: "/internalExamSchedule/create",
    update: "/internalExamSchedule/update",
    list: "/internalExamSchedule/list",
    delete: "/internalExamSchedule/delete",
    toggleStatus: "/internalExamSchedule/toggleStatus",
  },
  contact: {
    create: "/contact/create",
    update: "/contact/update",
    list: "/contact/list",
    delete: "/contact/delete",
    toggleEnable: "/contact/toggleEnable",
  },
  faq: {
    create: "/faq/create",
    update: "/faq/update",
    list: "/faq/list",
    delete: "/faq/delete",
  },
  guideline: {
    create: "/guideline/create",
    update: "/guideline/update",
    list: "/guideline/list",
    delete: "/guideline/delete",
  },

  invigilatorAllocation: {
    create: "/invigilatorAllocation/create",
    update: "/invigilatorAllocation/update",
    list: "/invigilatorAllocation/list",
    delete: "/invigilatorAllocation/delete",
    checkEmployeeAvailability:
      "/invigilatorAllocation/checkEmployeeAvailability",
    getAllocationDetails: "/invigilatorAllocation/getAllocationDetails",
    getInvigilatorAvailability:
      "/invigilatorAllocation/getInvigilatorAvailability",
    getMyInvigilationDuty: "/invigilatorAllocation/getMyInvigilationDuty",
    removeInvigilator: "/invigilatorAllocation/removeInvigilator",
  },

  allocatedRoom: {
    create: "/allocatedRoom/create",
    update: "/allocatedRoom/update",
    list: "/allocatedRoom/list",
    delete: "/allocatedRoom/delete",
    getDatesForExamSchedules: "/allocatedRoom/getDatesForExamSchedules",
    createSeatingArrangement: "/allocatedRoom/createSeatingArrangement",
    getSeatingArrangment: "/allocatedRoom/getSeatingArrangment",
    downloadSeatingArrangement: "/allocatedRoom/downloadSeatingArrangement",
    togglePublish: "/allocatedRoom/togglePublish",
  },
  seatingArrangement: {
    getDetailsForAttendance: "/seatingArrangement/getDetailsForAttendance",
    updateExamAttendance: "/seatingArrangement/updateExamAttendance",
  },
  studentExamResult: {
    getExamResults: "/studentExamResult/getExamResults",
  },
  setting: {
    update: "/setting/update",
    getDetails: "/setting/getDetails",
  },
  timetableDate: {
    create: "/timetableDate/create",
    list: "/timetableDate/list",
    update: "/timetableDate/update",
    delete: "/timetableDate/delete",
    getDetails: "/timetableDate/getDetails",
  },
  calenderEventName: {
    create: "/calenderEventName/create",
    update: "/calenderEventName/update",
    delete: "/calenderEventName/delete",
    list: "/calenderEventName/list",
  },
  sms: {
    getBalance: "/sms/getBalance",
  },

  wishlist: {
    create: "/wishlist/create",
    toggleEnableRegistration: "/wishlist/toggleEnableRegistration",
    delete: "/wishlist/deleteSubject",
    deleteWishlist: "/wishlist/delete",
    details: "/wishlist/details",
    register: "/wishlist/register",
    getSubjectsToRegister: "/wishlist/getSubjectsToRegister",
    update: "/wishlist/updateSubjectDetails",
    deleteRegistration: "/wishlist/deleteRegistration",
  },
};
