/** @format */

import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";

const CountDownTimer = ({ slotStartTime, slotEndTime }) => {
  const calculateTimeLeft = () => {
    const now = new Date();
    const [startHours, startMinutes] = slotStartTime.split(":");
    const [endHours, endMinutes] = slotEndTime.split(":");

    const startTime = new Date(now);
    startTime.setHours(parseInt(startHours, 10));
    startTime.setMinutes(parseInt(startMinutes, 10));

    const endTime = new Date(now);
    endTime.setHours(parseInt(endHours, 10));
    endTime.setMinutes(parseInt(endMinutes, 10));

    if (now > endTime) {
      return {
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    const difference = endTime - now;
    const totalSeconds = Math.floor(difference / 1000);
    const hoursLeft = Math.floor(totalSeconds / 3600);
    const minutesLeft = Math.floor((totalSeconds % 3600) / 60);
    const secondsLeft = totalSeconds % 60;
    return {
      hours: hoursLeft,
      minutes: minutesLeft,
      seconds: secondsLeft,
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [slotStartTime, slotEndTime]);

  return (
    <Box sx={{ padding: "5px", textAlign: "center" }}>
      <Typography>Time left </Typography>
      <Typography
        sx={{
          fontWeight: "bold",
          color: "red",
          fontSize: "20px",
          textAlign: "center",
        }}>
        {`${timeLeft.hours}h: ${timeLeft.minutes}m: ${timeLeft.seconds}s`}
      </Typography>
    </Box>
  );
};

export default CountDownTimer;
